* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.title {
    margin-bottom: 0.7em;
    background-color: #185836;
    color: #fff;
    padding: 0.2em;
    text-align: center;
    border-radius: 0.375em;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.1);
}

.results-container-title {
    margin-bottom: 0.5em;
}

body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    background-color: #f0f0f0;
    color: #185836;
    padding: 1em;
}

.container {
    max-width: 50em;
    margin: 2em auto;
    padding: 2em;
    background: #fff;
    border-radius: 0.75em;
    box-shadow: 0 0 0.9375em rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 1.25em;
    color: #185836;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 1.25em;
    margin-bottom: 2em;
}

label {
    margin-top:0.5em!important;
    font-size: 1.1em;
    margin-bottom: 0.5em;
    color: #185836;
    font-weight: bold;
}

input[type="text"],
input[type="number"] {
    width: 100%;
    padding: 0.75em;
    font-size: 1em;
    border: 0.125em solid #185836;
    border-radius: 0.375em;
    transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="text"]:focus,
input[type="number"]:focus {
    border-color: #ff9900;
    box-shadow: 0 0 0.5em rgba(255, 153, 0, 0.3);
    outline: none;
}

.results-container {
    margin-top: 1.5em;
    padding: 1.5em;
    background: #fff;
    border-radius: 0.75em;
    box-shadow: 0 0 0.9375em rgba(0, 0, 0, 0.1);
}

.results-container h1 {
    font-size: 1.8em;
    margin-bottom: 1.25em;
    text-align: center;
    color: #185836;
}

dl {
    padding: 1.25em;
    border-radius: 0.5em;
    background: #fff;
    border: 0.0625em solid #185836;
}

dt {
    font-weight: bold;
    margin-top: 0.625em;
    color: #185836;
}

dd {
    margin-left: 0;
    margin-bottom: 0.625em;
    color: #333;
}

button {
    padding: 0.75em 1.25em;
    font-size: 1.1em;
    color: #fff;
    background: #185836;
    border: none;
    border-radius: 0.375em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

button:hover {
    background: #133e2d;
    transform: translateY(-0.125em);
}

@media (max-width: 37.5em) {
    .container {
        padding: 1em;
    }

    h1 {
        font-size: 1.8em;
    }

    label {
        font-size: 1em;
    }

    input[type="text"],
    input[type="number"] {
        padding: 0.625em;
        font-size: 0.9em;
    }

    .results-container h1 {
        font-size: 1.5em;
    }

    dl {
        padding: 1em;
    }
}

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #185836;
    color: #fff;
    text-align: center;
    padding: 0.5em 0;
    font-weight: bold;
}

.results-container-title {
    margin-bottom: 0.4em !important;
}